<template>
    <el-form class="page" :model="pristup" ref="form" :label-position="'top'">
        <el-row>
            <el-col>
                <el-form-item size="large">
                    <el-tooltip class="item" effect="dark" :content="$t('global.nazad')" placement="top">
                        <el-button @click="$utils('goBack')" type="info" icon="fas fa-hand-point-left" circle></el-button>
                    </el-tooltip>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <el-form-item :label="$t('pristup.naziv')">
                    <select-grupe-korisnika class="full" :disabled="id !== 'create'" v-model="pristup.id_grupe_user" ref="grupe-korisnika"/>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <el-form-item>
                    <el-table
                            :data="pristup.pristupi"
                            border
                            stripe>
                        <el-table-column
                                prop="naziv_kontroler"
                                label="Kontroler">
                            <template slot-scope="scope">
                                {{scope.row.naziv_kontroler.replace(/_/g, ' ')}}
                            </template>
                       </el-table-column>
                        <el-table-column
                                prop="view"
                                label="Pregled"
                                align="center"
                                width="180" >
                            <template slot-scope="scope">
                                <el-checkbox border class="checkbox-in-grid green" :true-label="1" :false-label="0" v-model="scope.row.view"></el-checkbox>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="edit"
                                label="Edit"
                                align="center"
                                width="180" >
                            <template slot-scope="scope">
                                <el-checkbox border class="checkbox-in-grid green" :true-label="1" :false-label="0" v-model="scope.row.edit"></el-checkbox>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="create"
                                label="Dodavanje"
                                align="center"
                                width="180" >
                            <template slot-scope="scope">
                                <el-checkbox border class="checkbox-in-grid green" :true-label="1" :false-label="0" v-model="scope.row.create"></el-checkbox>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="delete"
                                label="Brisanje"
                                align="center"
                                width="180" >
                            <template slot-scope="scope">
                                <el-checkbox border class="checkbox-in-grid green" :true-label="1" :false-label="0" v-model="scope.row.delete"></el-checkbox>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <el-form-item size="large">
                    <el-button type="success" @click="onSubmit">{{ $t('global.sacuvaj') }}</el-button>
                    <el-button type="info" @click="$utils('goBack')">{{ $t('global.odustani') }}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
export default {
	name: 'pristup-edit',
	data() {
		return {
			fullscreenLoading: true,
			id: '',
			formCreate: '',
			id_drzave: null,
			pristup: {
				id_grupe_user: null,
				pristupi: [
				// 	{
				// 	id_kontroler: null,
				// 	view: null,
				// 	edit: null,
				// 	create: null,
				// 	delete: null
				// }
				]
			}
		};
	},
	mounted() {
		this.id = this.$route.params.id;
		this.formCreate = this.id === 'create';

		this.$store.dispatch('setHeader', {
			title: this.$t('pristup.pristup')
		});

		this.$utils('stopLoadingAfter', [this.getPristupi()]);
	},
	methods: {
		// Podaci
		getPristupi() {
			this.pristup.id_grupe_user = null;
			this.pristup.pristupi = [];
			if (!this.formCreate) {
				return this.$get('pristupi', { id: this.id }).then(data => {
					this.pristup.id_grupe_user = data.id_grupe_user;
					this.pristup.pristupi = data.pristupi;
				});
			}
			else
				this.getKontroleri();
		},
		getKontroleri() {
			this.pristup.id_grupe_user = null;
			this.pristup.pristupi = [];
			return this.$get('kontroleri').then(data => {
				data.forEach(p => {
					this.pristup.pristupi.push(p);
				});
			});
		},
		onSubmit() {
			return this.$refs.form.validate().then(() => {
				let data = JSON.stringify(this.pristup);
				this.$save('pristupi', data);
			}).catch((err) => {
				return err;
			});
		}
	},
	components: {
		selectGrupeKorisnika: require('../../components/select/grupeKorisnika').default
	}
};
</script>
